.modal {
  background-color: var(--gray000);
  width: 20rem;
  height: fit-content;
  max-height: 80vh;
  margin: auto;
  border-radius: 0.25rem;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
}
.modal .title:nth-of-type(1) {
  font-family: var(--typographyFontFamily);
  display: block;
}
.modal .title:nth-of-type(2) {
  font-family: var(--typographyFontFamily);
  display: none;
}
.modal.success .title:nth-of-type(1) {
  display: none;
}
.modal.success .title:nth-of-type(2) {
  display: block;
}
.modal .container:nth-of-type(1) {
  margin-bottom: 1rem;
  display: block;
}
.modal .container:nth-of-type(2) {
  margin-bottom: 1rem;
  display: none;
}
.modal.success .container:nth-of-type(1) {
  display: none;
}
.modal.success .container:nth-of-type(2) {
  display: block;
}
.modal .form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  height: 100%;
}
.modal.success .form {
  display: none;
}
.modal .confirmation {
  display: none;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 0 1rem;
}
.modal.success .confirmation {
  display: flex;
}

.title {
  padding: 1rem 1.5rem;
}
.error {
  font-family: var(--typographyFontFamily);
  font-size: .75rem;
  font-weight: bold;
  color: red;
  width: 15rem;
  margin: auto;
  display: block;
  margin-bottom: 1rem;
}
.link {
  font-family: var(--typographyFontFamily);
  font-size: .75rem;
  width: 15rem;
  margin: auto;
  display: block;
  cursor: pointer;
}
.error:empty {
  margin-bottom: 0;
}