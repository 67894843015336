.createSignatureTitleContainer{
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  width: 100px;
  height: 100%;
  align-items: center;
  background: var(--peach900);
  color: var(--gray000);
  border-bottom-right-radius: 25px;
}

.createSignatureTitleContainer .buttonStyle{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: none;
  color: var(--gray000);
}

.createSignatureTitleContainer .submitBox{
  background: var(--green700);
  margin: 0px 8px;
  padding-top: 10px;
  border: 3px solid var(--gray000);
  border-radius: 20px;
}

.createSignatureTitleContainer .submitButton{
  transform: scale(1.25);
  margin-bottom: 7px;
  color: var(--gray000);
}

.createSignatureTitleContainer .submitText{
  color: var(--gray000);
}

@media (orientation: portrait){
  /* .buttonStyle{
    transform: rotate(90deg);
  } */

  .createSignatureTitleContainer{
    transform: rotate(90deg);
    gap: calc(100vw / 15);
    width: 90px;
    padding-top: 15px;
  }
}

@media (max-height: 400px){
  .createSignatureTitleContainer button{
    font-size: 0.7rem;
  }
}