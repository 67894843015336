.rainbowWrapper {
  box-sizing: border-box;
  font-family: inherit;
  margin: 0;
  text-transform: none;
  position: relative;
  display: inline-flex;
  height: 2.75rem;
  animation: rainbow 2s infinite linear;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  white-space: nowrap;
  border-radius: .75rem;
  background-color: hsl(240, 6%, 11%);
  background-image: linear-gradient(var(--primaryBackground), var(--primaryBackground)),
    linear-gradient(90deg,
      hsl(0, 98%, 64%),
      hsl(92, 98%, 62%),
      hsl(208, 98%, 64%),
      hsl(196, 99%, 62%),
      hsl(268, 98%, 62%));
  background-size: 200%;
  font-size: .875rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: hsl(0 0% 98%);
  box-shadow:
    0 0 #0000,
    0 0 #0000,
    0 1px 3px 0 rgba(0, 0, 0, 0.12),
    0 1px 2px -1px rgba(0, 0, 0, 0.12);
  transition-duration: .15s;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.42, 0, 0.22, 1);
  background-clip: padding-box, border-box, border-box;
  background-origin: border-box;
  border: .07rem solid transparent;
}

.rainbowWrapper:hover {
  background-color: rgba(240 5.9% 10%, 0.9);
  /* Equivalent to hover:bg-primary/90 */
}

.rainbowWrapper:focus-visible {
  outline: none;
  ring: 1px solid;
  ring-color: 240 5% 64.9%;
  /* focus-visible:ring-ring */
}

/* Animation for "animate-rainbow" */
.rainbowWrapper {
  animation: rainbow 3s linear infinite;
}

@keyframes rainbow {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 200%;
  }
}

.rainbowWrapper::before {
  content: "";
  position: absolute;
  z-index: 0;
  height: 100%;
  width: 100%;
  animation: rainbow 2s linear infinite;
  background: linear-gradient(90deg,
      rgb(255, 70, 70),
      rgb(158, 255, 70),
      rgb(70, 158, 255),
      rgb(70, 205, 250),
      rgb(165, 70, 255));
  background-size: 200%;
  filter: blur(5.5px);
  cursor: pointer;
  display: block;
}