.wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.icon1{
  animation: pulse 1.2s linear infinite;
  position: relative;
  top: 5px;
}

@keyframes pulse {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.7;
    }

    100% {
        opacity: 0;
    }
}