.chatBubble {
  width: fit-content;
  border-color: var(--orange700);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chatBubble .lockContainer {
  position: absolute;
  top: 0;
  background-color: var(--orange700);
  display: flex;
  justify-content: center;
  height: 100%;
}

.chatBubble .lockIcon {
  width: 21px;
  height: 28px;
}