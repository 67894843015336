.input {
  font-family: var(--typographyFontFamily);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  /* color: rgba(0, 0, 0, 0.87); */
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 4px 0 5px;
  position: relative;
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  overflow: auto;
  scrollbar-width: thin;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-right: 1px;
  color: var(--gray920);
}

.input:focus {
  outline:none !important;
}

@keyframes shake {
  25% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  50% {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
  }
  75% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
}

.shake{
  animation: shake 300ms;
  -webkit-animation: shake 300ms;
  border-color: red !important;
}