.songPlayerViewBox {
position: relative;
  justify-content: column;
  align-items: flex-end;  
  margin-right: 30px;
  margin-left: -30px;
}

.songPlayerViewBoxButtons {
    position: absolute;
    top: -28px;
    z-index: 999;
    height: 55px;
    width: 75px;
    border-radius: 50px 50px 0px 50px;
    justify-content: center;
    align-items: center;
    background-color: var(--orange500);
    animation: open-button .25s linear;
}

.loadingBackground {
    height: calc(100% - 20px);
    width: calc(100% - 20px); 
    background-color: black;
}

@keyframes close-button {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes open-button {
    from { 
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.onOffButtonContainer {
    flex-direction: row;
    align-items: center;
    gap: 0px;
    justify-content: center;
    background-color: black;
    height: 90%;
    width: 90%;
    border-radius: 50px;
}

.closeButtons {
    animation: close-button .25s linear;
}

.offButton {
    height: 80%;
    width: 40%;
    font-size: 12px;
    color: black;
    background-color: var(--orange500);
    border-radius: 15px 2px 2px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    font-weight: 600;
    cursor: pointer;
    transition: all;
}

.onButton {
    height: 80%;
    width: 40%;
    font-size: 12px;
    color: black;
    background-color: var(--orange500);
    border-radius: 2px 15px 15px 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    font-weight: 600;
    cursor: pointer;
    transition: all;
}

.onButton:hover {
    box-shadow: inset 0 0 10px var(--orange970);
}

.onButton > span, .offButton > span {
    text-align: center;
}

@keyframes open-player {
    from {
        transform: scaleY(0.1);
        opacity: 0;
    }
    to {
        transform: scaleY(1);
        opacity: 1;

    }
}

@keyframes close-player {
    from {
        transform: scaleY(1);
    }
    to {
        transform: scaleY(0.1);
        opacity: 0;
    }
}

.viewBoxWrapper {
    position: absolute;
    top: 25px;
    min-height: 220px;
    min-width: 220px;
    height: 250px;
    width: 25vw;
    max-width: 500px;
    border-radius: 20px 0 20px 20px;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    background-color: var(--orange500);
    animation: open-player .25s linear;
}

.closingSongPlayer {
    animation: close-player .25s linear;
}

.controlContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1px auto 6px;
    height: 15px;
    width: 85px;
    background-color: none;
}

.playerControlsWrapper {
    position: relative;
    width: 155px;
    height: 75px;
    margin-top: -50px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
}

.leftControls svg, .rightControls svg, .centerControls svg {
    cursor: pointer;
}

.leftControls {
    position: absolute;
    left: 3px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    flex-direction: row;
    align-items: center;
    color: var(--gray000);
}

.rightControls {
    position: absolute;
    right: 20px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    flex-direction: row;
    align-items: center;
    color: var(--gray000);
}

.fastForward {
    height: 10px;
    width: 10px;
}

.centerControls {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

.playButton {
    margin-left: 5px;
}

.progressBar {
    flex-direction: row;
    width: calc(100% - 20px);
    transition: all 0.5s linear;
    background-color: var(--orange980);
    border-radius: 0px 0px 10px 10px;
    overflow:hidden;
}

.progressBarPlayed {
    opacity: 1;
    background-color: var(--peach900);
    border: none;
    transition: all;
}