.errandsFooterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -43px;
  position: relative;
}

.errandsFooterContainer::before {
  content: '';
  display: block;
  width: calc(100% - 2px);
  position: absolute;
  left: 1px;
  top: 0;
  background: linear-gradient(to bottom, var(--shadow000) 0%, var(--gray000) 100%);;
  height: 43px;
  pointer-events: none;
}

.errandsHoverBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  transition: top 0.5s ease-out;
  height: 100px;
}

.centerContainer {
  display: flex;
  height: 60px;
  width: 75px;
  border-radius: 50%;
  top: 15px;
  position: relative;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.circle {
  height: 35px;
  width: 35px;
  /* border: 1px solid var(--peach700); */
  background-color: var(--gray000);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 5px;
  cursor: pointer;
}

.bordered {
  border: 1px solid var(--peach700);
}

.circle:hover {
  animation: pulse-animation 1.5s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px var(--shadow404);
  }
  100% {
    box-shadow: 0 0 0 20px var(--shadow000);
  }
}

.add {
  color: var(--orange050);
}

.cross {
  transform: rotate(45deg);
  transition: transform 0.5s ease-out;
}

.plus {
  transform: rotate(0deg);
  transition: transform 0.5s ease-out;
}

.disable {
  cursor: not-allowed !important;
  transition: none;
}

.disableCircle{
  cursor: not-allowed !important;
}

.disableCircle:hover {
  animation: none;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 80px;
  margin-top: auto;
  background-color: var(--peach700);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  position: relative;
}

.buttonsContainer::before {
  content: '';
  display: block;
  position: absolute;
  height: 60px;
  width: 75px;
  background-color: var(--gray000);
  top: -44px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
}

.label {
  display: flex;
  flex-direction: column;
  color: var(--blue010) !important;
  font-size: 0.7rem;
  align-items: center;
  cursor: pointer;
  position: relative;
  overflow: visible;
  width: 40px;
  white-space: nowrap;
  transition: color .25s linear;
}

.label:hover {
  transition: color .25s linear;
  color: var(--blue010) !important;
}

.label > svg {
  transition: color .25s linear;
  stroke: var(--blue010) !important;
}

.label:hover > svg {
  transition: color .25s linear;
  stroke: var(--blue010) !important;
}

.moveUp {
  animation: slideUp 1s 1;
  animation-fill-mode: forwards;
}

@keyframes slideUp {
  0% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}

.moveDown {
  animation: slideDown 1s 1;
  animation-fill-mode: forwards;
}

@keyframes slideDown {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(10px);
  }
}

@keyframes slideDownLess {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(8px);
  }
}

@media (max-width: 800px){
  .label:hover {
    color: var(--blue010);
  }
}

@media (max-width: 56.25rem) {
  .moveUp,
  .moveDown {
    animation: none;
  }

  .label {
    top: 5px;
    font-size: 0.6rem;
    min-width: 40px;
  }
}

@media (min-width: 56.3rem){
  .label{
    top: 4px;
  }

  .moveDown{
    animation: slideDownLess 1s 1;
    animation-fill-mode: forwards;
  }
}

