.listItem {
  width: 100%;
  height: fit-content;
  list-style: none;
  list-style-type: none;
}

.listItem button {
  width: 100%;
  height: fit-content;
  margin: 0;
  padding: 10px 5px 10px 10px;
  border-top: 1px solid var(--peach200);
  background-color: var(--gray000);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.listItem img,
.listItem span,
.listItem svg {
  width: 24px;
  height: auto;
}

.listItem div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.listItem p {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  font-family: var(--typographyFontFamily);
  text-align: left;
  color: var(--orange700);
}

.listItem small {
  margin: 0;
  padding: 0;
  font-size: 10px;
  font-weight: 400;
  font-family: var(--typographyFontFamily);
  text-align: left;
  color: var(--gray600);
}

.listItem:hover p,
.listItem:focus-within p {
  font-weight: 700;
}

.listItem:hover small,
.listItem:focus-within small {
  font-weight: 500;
}