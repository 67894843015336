.wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  z-index: 9999999999999;
  pointer-events: none;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  margin: auto;
  z-index: 1000;
  width: 100%;
  transform: translateY(0%);
  transition: transform .5s ease;
  pointer-events: all;
}

.content * {
  pointer-events: all;
}

.type0 .content {
  transform: translateY(100%);
}

.type0 .content * {
  pointer-events: none;
}

.hasClickedConsent {
  animation: fade 250ms forwards;
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.consentTop {
  position: relative;
  height: fit-content;
  padding-top: 50px;
  margin-bottom: 3px;
  width: calc(100% - 32px);
  margin: auto;
  border-bottom: 3px solid var(--gray000);
}

.consentTop .intro,
.consentTop .terms {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  max-height: calc(100vh - 128px);
  max-height: calc(100svh - 128px);
  background-color: var(--peach700);
  border-radius: 48px 48px 0 0;
}

.consentTop .imgWrapper {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: -5px;
}

.consentTop .angelLogo {
  transform: translate(-50%, 0);
  width: 115px;
  height: 115px;
}

.isReferred .angelLogo {
  transform: translate(-10%, 0);
  transition: opacity 1s linear, transform 1s linear;
}

.consentTop .angelLogo img {
  width: 100%;
  height: 100%;
}

.consentTop .placeholder {
  transform: translate(50%, 0);
  width: 115px;
  height: 115px;
  opacity: 0;
}

.isReferred .placeholder {
  transform: translate(0, 0);
  opacity: 0;
  transition: opacity 1s linear, transform 1s linear;
}

.consentTop .placeholder img {
  width: 100%;
  height: 100%;
}

.consentTop .referrerLogo {
  transform: translate(-50%, 0);
  width: 95px;
  height: 95px;
  z-index: 1;
  opacity: 0;
}

.isReferred .referrerLogo {
  border: 2px solid var(--orange700);
  border-radius: 50%;
  transform: translate(10%, 0);
  opacity: 1;
  overflow: hidden;
  transition: opacity 1s linear, transform 1s linear;
}

.consentTop .referrerLogo img {
  width: 100%;
  height: 100%;
}

.consentTop h1 {
  padding: 50px 15px 0 15px;
  color: var(--orange700);
  font-family: var(--typographyFontFamily);
  font-weight: bolder;
  letter-spacing: 4.37px;
  font-size: 18px;
}

.consentTop .terms h1 {
  padding: 15px 15px 0 15px;
  color: var(--orange700);
  font-family: var(--typographyFontFamily);
  font-weight: bolder;
  letter-spacing: 4.37px;
  font-size: 16px;
}

.consentTop h2 {
  padding: 5px 15px 5px 15px;
  display: block;
  position: relative;
  color: var(--gray950);
  text-align: center;
  margin: 0;
  font-family: var(--typographyFontFamily);
  font-weight: 500;
  font-size: 13px;
}

.consentTop h3 {
  padding: 0 15px 5px 15px;
  font-size: 1.1em;
  animation: openCourtesy 1s forwards;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  font-family: var(--typographyFontFamily);
  font-size: 14px;
}

@keyframes openCourtesy {
  0% {
    clip-path: polygon(50% 0, 50% 0, 50% 100%, 50% 100%);
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

.consentTop p {
  padding: 0 15px 10px 15px;
  font-size: 11px;
  font-weight: 400;
  font-family: var(--typographyFontFamily);
  overflow-y: auto;
  scrollbar-width: thin;
  height: fit-content;
  max-height: 100%;
  animation: dud 0s forwards;
}

@keyframes dud {
  100% { display: block }
}

.consentTop strong {
  font-weight: 500;
}

.consentTop a {
  color: var(--orange700);
}

.consentBottom {
  position: relative;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  background-color: var(--peach700);
  width: calc(100% - 32px);
  margin: auto;
  padding: 8px;
  border-radius: 8px;
}

.consentBottom > div {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  max-width: 618px;
  width: 100%;
  flex-wrap: wrap;
}

.consentBottom a {
  border: 1px solid var(--peach900);
  position: relative;
  border-radius: 8px;
  height: 40px;
  width: 140px;
  cursor: pointer;
  font-family: var(--typographyFontFamily);
  margin-top: 8px;
  background-color: var(--gray820);
  color: var(--gray000);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.consentBottom button {
  border: 1px solid var(--peach900);
  position: relative;
  border-radius: 8px;
  height: 40px;
  width: 140px;
  cursor: pointer;
  font-family: var(--typographyFontFamily);
  margin-top: 8px;
  background-color: var(--green800);
  color: var(--gray000);
}

.consentBottom button:first-child {
  border: 1px solid var(--peach900);
  position: relative;
  border-radius: 8px;
  height: 40px;
  width: 140px;
  cursor: pointer;
  font-family: var(--typographyFontFamily);
  margin-top: 8px;
  background-color: var(--gray820);
  color: var(--gray000);
}

.consentBottom button:hover,
.consentBottom button:focus,
.consentBottom a:hover,
.consentBottom a:focus{
  box-shadow: 0 0 5px var(--orange600);
  font-weight: bold;
  letter-spacing: 0.5px;
  color: var(--gray000);
}
