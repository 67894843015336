.isPrivate {
  /* --orange700: var(--orange700Private); */
  --peach000: var(--blue000);
  /* --peach600: var(--peach600Private); */
  /* --gray000: var(--gray000Private); */
  --gray600: var(--gray700);
  --gray920: var(--blue990);
  ----orange050: var(--blue700);
  --orange000: var(--blue100);
  --peach500: var(--blue100);
  --orange600: var(--blue700);
  /* --orange050: var(--orange700Private); */
}

.infiniteScroll{
  justify-content: flex-end;
}
