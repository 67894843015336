.base {
  background-color: var(--peach600);
  border: 1px solid var(--orange700);
  border-radius: 8px;
  width: 294px;
  height: 37px;
  display: flex;
  position: relative;
}

.alignByCurrentUser {
  background-color: var(--gray000);
}

.Action {
  width: 284px;
  height: 48px;
}

.Audio {
  width: 300px;
  height: 87px;
}

.CalculatorResult {}

.Coupon {}

.Disclaimer {
  display: none;
}

.Document {
  width: 200px;
  height: 200px;
}

.Errand {
  width: 270px;
  height: 135px;
  margin: 8px 0;
}

.Field {
  width: 224px;
  height: 33px;
}

.HelpTip {}

.HelpTipGroup {}

.Invite {}

.LockConfirmation {}

.Notification {}

.Order {}

.PermissionRejected {}

.PropertyListing {}

.Referrer {
  width: 314px;
  height: 94px;
}

.SignatureConfirmation {}

.Song {
  width: 244px;
  height: 205px;
}

.Text {}

.Url {}

.Video {}

.deletedMessageContent {
  width: 294px;
  height: 37px;
}

.privateMessageContent {
  width: 240px;
  height: 111px;
}
