.sendArrowContainer{
  width: 35px;
  height: 35px;
  background: var(--peach500);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  
}

.sendArrowContainerAttachmentMorphRules {
  width: 31px;
  height: 31px;
  background: var(--peach500);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 3px solid white; 
}

.arrow{
  color: var(--peach100);
  height: 20px;
  width: 20px;
  margin-left: 3px;
}

.arrowAttachmentMorphRules {
  color: white;
  height: 14px;
  width: 14px;
  margin-left: 3px;
}

.attachmentMorphRules::before {
  transform: scaleX(-1);
  position: absolute;
  top: -7px;
  width: 10px;
  height: 10px;
  content: '';
  display: block;
  left: -9px;
  background: radial-gradient(circle at 100% 100%, transparent 8px, var(--orange000) 9px, var(--orange000) 10px);
}

.maxHeight{
  position: relative;
  align-items: center;
  display: flex;
  height: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  justify-content: center;
  width: 50px;
  justify-content: center;
  align-items: center;
}

.privateChat{
  padding-top: 13px;
  background-color: var(--peach000) !important;
  border: 1px solid var(--orange000) !important;
  border-top: none !important;
}

.privateChat::before{
  position: absolute;
  left: -10px;
  right: unset;
  top: -3px;
  width: 0.625rem;
  height: 0.625rem;
  content: '';
  display: block;
  z-index: 1002;
  background: radial-gradient(circle at 0% 100%, transparent 0.5rem, var(--orange000) 0.5625rem, var(--peach000) 0.625rem);
}

.privateChat::after{
  position: absolute;
  right: -1px;
  top: -5px;
  width: 48px;
  height: 11px;
  content: '';
  display: block;
  z-index: 1002;
  background: var(--peach000);
  border-right: 1px solid var(--orange000);
}

.replyChat{
  padding-top: 14px;
  background-color: var(--peach000);
  border: 1px solid var(--orange000);
  border-top: none !important;
  height: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: var(--gray000) !important;
  position: relative;
  display: flex;
  align-items: center;
}

.replyChat::before{
  position: absolute;
  left: -10px;
  right: unset;
  top: -3px;
  width: 0.625rem;
  height: 0.625rem;
  content: '';
  display: block;
  z-index: 10;
  background: radial-gradient(circle at 0% 100%, transparent 0.5rem, var(--orange000) 0.5625rem, var(--gray000) 0.625rem);}

.replyChat::after{
  position: absolute;
  right: -1px;
  top: -14px;
  width: 48px;
  height: 16px;
  content: '';
  display: block;
  z-index: 1002;
  background: var(--gray000);
  border-right: 1px solid var(--orange000);
}

.editChat, .editChat::after{
  background: var(--gray000) !important;
}

.editChat::before {
  background: radial-gradient(circle at 0% 100%, transparent 0.5rem, var(--orange000) 0.5625rem, var(--gray000) 0.625rem);
}

.walletChatMobile {
  margin-right: 3px;
  padding-top: 9px;
  padding-bottom: 9px;
  background-color: #01011A !important;
  border-radius: 0px 0px 5px 0px;
}

.walletChatDesktop {
  background-color: #01011A !important;
  border-radius: 0px 5px 5px 0px;
}

.walletSendButton {
  padding-right: 1px;
}

.walletArrowContainer {
  background-color: white;
}

.walletArrow {
  color: #1D268C;
}

.privateArrow {
  color: var(--peach000);
}

.transparent{
  background: transparent;
}

.gray{
  background: var(--gray999);
}

.disabled{
  display: none !important;
}

.dropShift{
  top: -1px;
}

.dropWidth{
  width: 30px;
}

.shiftDown{
  top: 6px;
}

.shiftMoreDown{
  top: 9px;
}

.shiftWelcomeDown{
  top: 7px;
}

.centerIcons{
  bottom: 7px;
  position: relative;
}

.selectMultiple {
  background-color: var(--blue995);
}

.selectMultiple::before {
  background-color: var(--blue995);
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: -2px;
  height: 2px;
}

@media (max-width: 900px) {
  .dropShift{
    top: 4px;
  }

  .suggestionsChat{
    height: 61px;
  }

  .privateChat, .replyChat{
    z-index: 5;
    bottom: 0px;
  }

  .privateChat::before, .replyChat::before{
    top: 0px;
  }

  .shiftWelcomeDown{
    top: 9px;
  }
}


.grayedOut{
  filter: grayscale(100%);
  cursor: not-allowed;
}

.pointsTableBackground { 
  background-color: var(--orange054);
  border-radius: 0px 5px 5px 0px;
}