ol.bubbleList {
  list-style: none;
  counter-reset: item; /* To display the item number (1, 2, 3...) */
  display: relative;
}

ol.bubbleList > li {
  counter-increment: item; /* To display the item number (1, 2, 3...) */
  display: flex;
  margin-bottom: -4px;
  margin-top: -4px;
}

/* The orange number bubbles */
ol.bubbleList > li:before {
  margin-right: -2px;
  content: counter(item); /* To display the item number (1, 2, 3...) */
  background: var(--orange800);
  border-radius: 50%;
  color: white;
  width: 20px;
  height: 19px;
  padding-top: 1px;
  text-align: center;
  position: relative;
  margin-left: -10px;
  transform: translateX(-50%);
}

/* Don't display the quotation marks around links for bubbleList */
ol.bubbleList > li a[href^="#"]::before {
  display: none;
}
ol.bubbleList > li a[href^="#"]::after {
  display: none;
}