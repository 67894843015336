.rootContainer {
  height: 100px;
  width: 100px;
}

.topCircle {
  display: flex;
  justify-content: center;
  position: absolute;
  background-color: var(--peach600);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  top: -20px;
  left: calc(50% - 10px);
  translate: -50%;
  border: solid 1px var(--orange700);
}

.topCircle::before {
  content: '';
  position: absolute;
  background-color: var(--peach600);
  bottom: -1px;
  width: calc(100% + 4px);
  height: calc(50% + 21px);
  left: 50%;
  translate: -50%;
}

.prize {
  width: 70px;
  height: 70px;
  object-fit: contain;
  position: absolute;
  opacity: 0;
  left: calc(50% - 10px);
  top: 50%;
  translate: -50% -50%;
  z-index: 1;
}
.prizeAnimation {
  animation: prizeAnimation 4s linear 1;
  animation-fill-mode: forwards;
}
.truck {
  height: 30px;
  width: 60px;
  position: absolute;
  bottom: 0px;
  left: -260px;
  z-index: 2;
}
.truckAnimation {
  animation: truckAnimation 4s linear 1;
  animation-fill-mode: forwards;
}

.fireworks {
  width: 115px;
  height: 100px;
  position: absolute;
  opacity: 1;
  left: calc(50% - 10px);
  top: -10px;
  translate: -50%;
  z-index: 1;
  user-select: none;
}

.fireworksAnimation {
  animation: fireworksAnimation 4s linear 1;
  animation-fill-mode: forwards;
}

@keyframes prizeAnimation {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  /* 1. Appear */
  20% {
    opacity: 1;
    transform: scale(1);
  }
  /* 2. Scale down and move up */
  40% {
    opacity: 1;
    transform: translateY(-30px) scale(1);
  }
  /* 3. Scale down and move down */
  60% {
    opacity: 1;
    transform: translateY(30px) scale(0.1);
  }
  /* 4. fade away quickly*/
  65% {
    opacity: 0;
    transform: translateY(30px) scale(0.1);
  }
  /* move back to origin while invisible */
  90% {
    opacity: 0;
    transform: scale(0);
  }
  /* return to full size and keep */
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes truckAnimation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  20% {
    opacity: 1;
    transform: scale(1);
  }
  40% {
    opacity: 1;
    transform: translateX(275px);
  }
  60% {
    opacity: 1;
    transform: translateX(275px);
  }
  65% {
    opacity: 1;
    transform: translateX(275px);
  }
  90% {
    opacity: 1;
    transform: translateX(500px);
  }
  95% {
    opacity: 0;
    transform: translateX(500px);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes fireworksAnimation {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  20% {
    opacity: 1;
    transform: scale(1);
  }
  40% {
    opacity: 0;
    transform: translateX(40px) scale(1);
  }
  90% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
