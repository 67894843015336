.userInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.nameButton,
.nameLabel {
  color: var(--blue010);
  font-family: var(--typographyFontFamily);
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
  padding: 0 .25em;
}

.openAppButton {
  display: inline-flex;
  border: 1px solid var(--gray000);
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  height: 25px;
  color: var(--orange700);
  background-color: var(--gray000);
  font-family: var(--typographyFontFamily);
  transition: all 0.6s linear;
  overflow: hidden;
  text-wrap: nowrap;
  text-decoration: none;
  text-align: center;
  align-items: center;
}

.openAppButton > p {
  font-size: 11px;
}