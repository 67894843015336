.skeletonWrapper {
    display: flex;
    width: 350px;
    flex-direction: row;
    justify-content: flex-start;
}

.textSkeleton {
    max-width: 350px;
    height: 100px;
    border-radius: 1.25rem;
}

.codeSkeleton {
    width: 100px;
    height: 100px;
    border-radius: 1.25rem;
}

@media(max-width: 900px){
    .skeletonWrapper{
        max-width: min(90%, calc(100vw - 50px));
    }
    .codeSkeleton {
        width: 85px;
    }
    .textSkeleton{
        width: calc(100% - 85px);
    }
}