@keyframes rotateZ {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

@keyframes popupShowsUp {
  from {
    margin-left: 0;
    top: 0;
    transform: scale(0);
  }

  to {
    margin-left: 244px;
    top: -33px;
    transform: scale(1);
  }
}


.tapToInstallButton {
  overflow: hidden;
  /* Ensures the reflection stays inside the tapToInstallButton */
  cursor: pointer;
}

/* The wider light reflection effect (before pseudo-element) */
.tapToInstallButton::before {
  content: '';
  position: absolute;
  top: 0;
  left: -150%;
  /* Start the reflection further to the left */
  width: 250%;
  /* Make the reflection wider */
  height: 100%;
  background: linear-gradient(120deg, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 70%);
  transform: skewX(25deg);
  /* Skew adjusted to 25deg */
  transition: left 0.6s ease;
  /* Smooth transition on hover */
}

/* Move the reflection to the right on hover */
.tapToInstallButton:hover::before {
  left: 20%;
}

.patentProtectedButton::before {
  content: '';
  position: absolute;
  top: 0;
  left: -150%;
  /* Start the reflection further to the left */
  width: 250%;
  /* Make the reflection wider */
  height: 100%;
  background: linear-gradient(120deg, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 70%);
  transform: skewX(25deg);
  /* Skew adjusted to 25deg */
  transition: left 0.6s ease;
  /* Smooth transition on hover */
}

/* Move the reflection to the right on hover */
.patentProtectedButton:hover::before {
  left: 20%;
}


@keyframes reveal {
  0% {
    opacity: 0;
    transform: translateY(300px);
    /* Start from below the screen */
  }

  70% {
    transform: translateY(-30px);
    /* Move a bit above the final position */
  }

  85% {
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
    /* Settle at the final position */
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    transform: translateY(0px);
    /* Start from below the screen */
  }

  100% {
    transform: translateY(500px);
    opacity: 0;
    /* Settle at the final position */
  }
}

@keyframes fade-out-mobile {
  0% {
    opacity: 1;
    transform: translateY(0px) scale(0.7);
    /* Start from below the screen */
  }

  100% {
    transform: translateY(500px) scale(0.7);
    opacity: 0;
    /* Settle at the final position */
  }
}

@keyframes reveal-late {
  0% {
    transform: translateY(500px);
    /* Start from below the screen */
  }

  70% {
    transform: translateY(-30px);
    /* Move a bit above the final position */
  }

  85% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
    /* Settle at the final position */
  }
}
@keyframes reveal-late-mobile {
  0% {
    transform: translateY(500px) scale(0.7);
    /* Start from below the screen */
  }

  70% {
    transform: translateY(-30px) scale(0.7);
    /* Move a bit above the final position */
  }

  85% {
    transform: translateY(10px) scale(0.7);
  }

  100% {
    transform: translateY(0) scale(0.7);
    /* Settle at the final position */
  }
}


.reveal {
  opacity: 0;
  /* Initial hidden state */
  animation: reveal 1s ease forwards;
  /* 1s animation duration */
}

.reveal-item-patent {
  opacity: 0;
  animation: reveal 1s ease forwards;
  animation-delay: 1s;
}

.reveal-item {
  opacity: 0;
  animation: reveal 1s ease forwards;
  animation-delay: 0.6s;
  /* Adjust delay for each item */
}

.reveal-item-late {
  transform: translateY(500px);
  animation: reveal-late 1s ease forwards;
  animation-delay: 0.8s;
  /* Adjust delay for each item */
}

.reveal-item-late-mobile {
  transform: scale(0.7) translateY(500px);
  animation: reveal-late-mobile 1s ease forwards;
  animation-delay: 0.8s;
}

.mobile-circle-image-container {
  transform: scale(0.7) translateY(500px);
}


.fadeOut {
  transform: translateY(0px);
  opacity: 1;
  animation: fade-out 1.3s ease forwards;
}

.fadeOutLate {
  transform: translateY(0px);
  opacity: 1;
  animation: fade-out 1.3s ease forwards;
  animation-delay: 0.5s;
}

.fadeOutLateMobile {
  opacity: 1;
  transform: scale(0.7) translateY(0px);
  animation: fade-out-mobile 1.5s ease forwards;
  animation-delay: 0.5s;
}

.fadeOutLateLate {
  transform: translateY(0px);
  opacity: 1;
  animation: fade-out 1.5s ease forwards;
  animation-delay: 0.2s;
}