.hide{
  display: none;
}
.show{
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  padding-right: 5px;
}

.micContainerWrapper{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.micContainer{
  width: 35px;
  height: 35px;
  background: var(--peach900);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.mic{
  color: var(--peach100);
  height: 20px;
  width: 20px;
}

.micOff{
  color: var(--orange900);
  height: 37px;
  width: 40px;
  padding: 5px;
  transform: translateY(5px);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 5px;
}

.micOff svg{
  padding: 1px;
  width: 40px;
  height: 40px;
}

.micOff:hover{
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 50%;
}

.disable{
  cursor: not-allowed;
}

.shift{
  margin-top: 15px;
}