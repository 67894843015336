.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.loadingStack {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: poppins;
}

.loadingHide {
    height: 0px;
    width: 0px;
    visibility: hidden;
}

.loadingVisible {
    height: 90vh;
    width: 90vw;
    visibility: visible;
}

.mid {
    animation: 2s ease blink infinite;
}
  
.right {
    animation: 2s ease blink infinite;
    animation-delay: 0.4s;
}

@keyframes blink {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}