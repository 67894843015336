.pulse {
  animation: Pulse 2000ms ease-in-out infinite;
}

@keyframes Pulse {
  0% {  opacity: 1 }
  50% { opacity: 0.75 }
  100% { opacity: 1 }
}

.fade {
  animation: Fade 1000ms ease-in-out;
}

@keyframes Fade {
  0% {  opacity: 1 }
  45% { opacity: 0 }
  55% { opacity: 0 }
  100% { opacity: 1 }
}