.wrapper {
  bottom: 0;
  z-index: 1300;
  animation: fadeIn 1s ease-in;
}

.noFade{
  animation: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    max-height: 0px;
  }
  to {
    opacity: 1;
    max-height: 200px;
  }
}

.content {
  align-items: center;
  justify-content: center;
  margin-left: auto;
  z-index: 1000;
  background: var(--gray999);
  width: 500px;
  max-width: calc(100% - 40px);
  margin-right: 30px;
  font-size: 11px;
  -webkit-text-size-adjust: none;
  font-family: var(--typographyFontFamily);
  border-radius: 5px;
  padding: 5px;
  border-bottom-right-radius: 0px;
  color: var(--gray600);
  line-height: 125%;
}

.content a{
  color: var(--gray600);
}

.welcomeContent {
  position: absolute;
  right: 0;
  bottom: 95px;
}

.slotMachineContent {
  position: absolute;
  right: 0;
  bottom: 110px !important;
}

.sendButtonContainer{
  display: flex;
  position: absolute;
  right: 0;
  margin-right: 30px;
  width: 50px;
  height: 50px;
  z-index: 1000;
  background: var(--primaryBackground);
}

.booleanContainer{
  width: 122px;
}

.welcomeSpacer{
  height: 20px;
  width: 50px;
}

.connector{
  width: 50px;
  height: 7px;
  position: absolute;
  right: 0;
  margin-right: 30px;
  background: transparent;
}

.welcomeConnector{
  height: 20px;
  transform: translateY(-14px);
  background: var(--gray999);
}

.slotMachineConnector{
  height: 35px;
  transform: translateY(-29px);
  background: var(--gray999);
}

.booleanConnector{
  width: 114px;
  background-color: transparent;
}

.connector::before{
  position: absolute;
  left: -8px;
  right: unset;
  top: -2px;
  width: 0.625rem;
  height: 0.625rem;
  content: '';
  display: block;
  z-index: 1002;
  background: radial-gradient(circle at 0% 100%, transparent 0.5rem, var(--gray999) 0.5625rem, var(--gray999) 0.625rem);
}

.dropdown{
  right: 7px;
}

.actionContainer{
  height: 56px;
}

.welcomeContainer{
  height: 74px;
}

.slotMachineContainer{
  height: 74px;
}

.halfBox{
  background: var(--gray000);
  width: 10px;
  z-index: 1001;
  border: 1px solid var(--peach400);
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.welcomeHalf{
  border: 1px solid var(--peach700);
  border-left: none;
}

.focusedBox{
  border: 1px solid var(--peach700);
  border-left: none;
}

.sendButton{
  z-index: 1001;
  background: var(--gray999);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-left: 3px;
}

.checkbox{
  border: 1px solid var(--gray600);
  display: flex;
  width: 20px;
  height: 20px;
}

.checkContainer{
  display: inline;
  border: 1px solid var(--gray600);
  width: 13px;
  height: 13px;
  border-radius: 3px;
  margin-right: 4px;
  position: relative;
  top: 1px;
  cursor: pointer;
}

.uncheck{
  display: inline-flex;
  width: 13px;
  height: 13px;
  border: 1px solid var(--gray600);
  border-radius: 3px;
  cursor: pointer;
  margin-right: 4px;
  top: 1px;
  position: relative;
}

.disabledSendButton{
  filter: grayscale(100%)
}
.singleLine{
  bottom: 95px;
}

.doubleLine{
  bottom: 117px;
}

.welcomeShaking{
  bottom: 11px;
}

.slotMachineShaking{
  bottom: 25px !important;
}

@media (max-width: 900px) {

  .content {
    margin-right: 20px;
  }

  .sendButtonContainer{
    margin-right: 20px;
  }

  .connector{
    margin-right: 20px;
  }

  .welcomeHalf{
    height: 72px;
  }

  .halfBox{
    margin-top: 3px;
  }
}

@media(max-width: 401px) {
  .welcomeConnector{
    height: 39px !important;
  }

  .welcomeContainer{
    height: 100px !important;
  }

  .slotMachineConnector{
    height: 35px !important;
    transform: translateY(-27px);
  }

  .slotMachineContainer{
    /* height: 100px !important; */
  }
}

.booleanIcon{
  border-radius: 5px;
  height: 15px;
  width: 70px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.booleanText{
  display: inline-flex;
}

.yesButton{
  border-radius: 5px;
  background-color: var(--green850);
  color: white;
  width: fit-content;
  height: 15px;
  padding: 0 3px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: -3px;
}

.noButton{
  border-radius: 5px;
  /* border: 1px solid var(--blue999); */
  background-color: var(--blue850);
  color: white;
  width: fit-content;
  height: 15px;
  padding: 0 3px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: -3px;
}

.slash{
  color: var(--blue999);
  transform: rotate(-45deg);
}

.shaking{
  animation: shake 300ms;
  -webkit-animation: shake 300ms;
}

@keyframes shake {
  25% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  50% {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
  }
  75% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
}

.sendArrowCircle{
  background-color: var(--orange900);
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--peach100);
  height: 15px;
  width: 15px;
}

.content i {
  font-size: 0.75em;
  vertical-align: middle;
  font-style: normal; /* Prevents italicization */
}

.mobileSend{
  transform: translateX(1px);
}