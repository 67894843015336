.slide {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  outline: 0.125rem solid var(--peach900);
  outline-offset: -0.0625rem;
  position: absolute;
  top: 0;
  left: 100%;
  background-color: var(--gray000);
  z-index: 999;
  border-radius: .5rem;
  transition: all 1s linear;
}

.showUserPromptList {
  left: 0;
}

@media (max-width: 900px) {
  .slide {
    border-radius: 0;
  }
}

.slide header {
  background-color: var(--peach800);
  color: var(--blue999);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  font-family: var(--typographyFontFamily);
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.slide header::after {
  content: '';
  display: block;
  width: calc(100% - 2px);
  height: 20px;
  position: absolute;
  left: 1px;
  bottom: -20px;
  background: linear-gradient(var(--gray000), transparent);
  z-index: 2;
  pointer-events: none;
}

.slide header.openSearchHeader .openSearchContainer {
  background-color: var(--gray010);
  border-radius: 10px;
  height: 35px;
}

.slide header.openSearchHeader .searchIcon{
  pointer-events: none;
  color: var(--orange700);
}

.slide header.openSearchHeader .closeIcon{
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -12px;
  margin-right: 15px;
  padding-top: 5px;
}

@media screen and (max-width: 600px) {
  .slide header.openSearchHeader .closeIcon{
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -12px;
    margin-right: 5px;
    padding-top: 5px;
  }
}

.slide header.openSearchHeader .inputBase{
  font-weight: 500;
  color: var(--gray410);
  width: 70%;
  padding-right: 25px;
  background-color: var(--gray010);
}

.slide header.closeSearchHeader button{
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -16px;
  margin-right: 5px;
  padding-top: 5px;
}

.slide ul {
  position: relative;
  margin-bottom: auto;
  max-height: calc(100% - 165px);
}

.slide ul > div {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  width: calc(100% - 2px);
  transform: translateX(1px);
}