.loader {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: var(--peach000);
}

/**
 * The background color of var(--gray000)66 is intentionally translucent
 * in order to keep the appearance of the shimmer effect consistent
 * regardless of what background color is used for the skeleton
 */
.loader::after {
  content: '';
  position: absolute;
  top: 0;
  width: 50px;
  height: 100%;
  background-color: var(--gray000)66;
  transform: skewX(-20deg);
  animation: shimmer 2.5s infinite;
}

@keyframes shimmer {
  0% {
    left: -100px
  }

  100% {
    left: 100vw
  }
}