.base {
  color: var(--blue999);
  font-family: var(--typographyFontFamily);
  font-size: 12px;
  font-weight: normal;
  line-height: 150%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  margin: 0;
  padding: 0;
  z-index: 1;
  pointer-events: none;
}

.editing {
  color: var(--blue130);
  -webkit-text-stroke: 0.3px black;
  stroke: 0.3px black;
}

.cMessageAuthor {
  color: var(--orange050);
}

.fsItalic {
  font-style: italic;
}

.dFlex {
  display: flex;
}

.lc1 {
  -webkit-line-clamp: 1;
}

.lc2 {
  -webkit-line-clamp: 2;
}

.lc3 {
  -webkit-line-clamp: 3;
}

.lhNarrow {
  line-height: 100%;
}

.taCenter {
  text-align: center;
}

.taRight {
  text-align: right;
}

.mlAuto {
  margin-left: auto;
}

.mrAuto {
  margin-right: auto;
}

.mAuto {
  margin-left: auto;
  margin-right: auto;
}

.fwBold {
  font-weight: bold;
}

.fwMedium {
  font-weight: medium;
}

.fsLargest {
  font-size: 16px;
}

.fsLarge {
  font-size: 14px;
}

.fsSmall {
  font-size: 11px;
}

.fsSmallest {
  font-size: 10px;
}

.mbLargest {
  margin-bottom: 16px;
}

.mbLarge {
  margin-bottom: 8px;
}

.mbSmall {
  margin-bottom: 4px;
}

.mbSmallest {
  margin-bottom: 2px;
}

.mtLargest {
  margin-top: 16px;
}

.mtLarge {
  margin-top: 8px;
}

.mtSmall {
  margin-top: 4px;
}

.mtSmallest {
  margin-top: 2px;
}

.order {
  margin-top: 25px;
  /* padding-left: 125px; */
}

.mlAuto.taRight.order {
  margin-top: 25px;
  padding-right: 125px;
}

.ttCapitalize {
  text-transform: capitalize;
}

.ttUppercase {
  text-transform: uppercase;
}

.ttLowercase {
  text-transform: lowercase;
}