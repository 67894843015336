* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Honey Script';
  src: url('./Assets/Fonts/HoneyScript.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

body {
  font-size: 1rem;
  margin: 0;
  font-family: '-apple-system, BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Poppins', Helvetica, Arial,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.00938em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

/* remove default appearance for checkbox, allows the styling of checkboxes */
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none; /* for older versions of webkit browsers */
  -moz-appearance: none; /* for firefox */
}

.sr-only,
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  border: 0;
  white-space: nowrap;
}

.mui-transparent-background > div {
  background-color: transparent !important;
}

.grecaptcha-badge {
  display: none !important;
}

/* spinning gradient on splash, 359 needed as 360 can cause stutters */
@keyframes gradient {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

.meditating-morgan-error {
  padding: 5px;
  background: var(--shadow110);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.meditating-morgan-error span {
  background-color: var(--orange925);
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  height: 45px;
  min-height: 45px;
  max-height: 45px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.meditating-morgan-error img {
  width: 45px;
  height: auto;
  animation: floatingError 10s infinite ease;
}

.meditating-morgan-error p {
  font-family: 'Poppins', sans-serif;
  color: var(--orange925);
} 
@keyframes floatingError {
  0% {
    transform: translateY(0px);
  }
  16% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0px);
  }
  84% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
}