div.fallback {
  width: 149px;
  height: 25px;
  border-radius: 10px;
  margin-bottom: 11px;
  background-color: var(--peach600);
  border: 1px solid var(--orange800);
}

.outerWrapper {
  max-width: min(90%, calc(100vw - 50px));
}

.chatBubble {
  display: flex;
  align-items: center;
  background-color: var(--peach600);
  border-color: var(--orange800);
}

.gifWrapper {
  background-color: var(--orange800);
  border-radius: 7px;
  width: 50px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gifImg {
  min-width: 100%;
  max-width: 100%;
  margin: -2px 0 -2px 0;
}

.messageWrapper {
  padding: 4px 8px 4px 2px;
}

/* Ensure the gif and message do not get split into two lines on smaller mobile screens */
@media (max-width: 365px) {
  .gifWrapper {
    width: 36px;
    padding: 4px 0 4px;
    height: 32px;
  }

  .messageWrapper {
    padding-right: 4px;
  }

  .outerWrapper {
    max-width: 98%;
  }
}
