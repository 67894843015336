.useDefaultBackgroundColor {
  background-color: var(--peach700) !important;
}

.useDefaultBorderColor {
  border-color: var(--peach700) !important;
}

.welcomeContainer{
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  z-index: 1;
  margin-top: -20px;
}

.WCDefaultWidth {
  width: 285px;
}

.WCSlideOutMobileWidth {
  width: 200px;
}

.WCSlideOutMobileFontSize {
  font-size: 0.8rem;
}

.noBottomBorderRadius {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.operatorView {
  margin-top: 0px;
}

.pointer{
  cursor: pointer;
}

.top{
  background-color: var(--blue996);
  height: fit-content;
  z-index: 2;
  display: flex;
  align-items: end;
  padding: 10px;
  font-weight: 600;
  font-size: 0.9rem;
  color: var(--gray000);
  justify-content: center;
}

.posRelative {
  position: relative;
}

.topMarginsPaddings {
  padding-top: 45px;
  margin-top: 60px;
}

.topBorderRadiuses {
  border-radius: 10px 10px 0 0;
}

.bottom{
  background-color: var(--peach600);
  height: 50%;
  border: 1px solid var(--blue996);
  z-index: 2;
}
.bottomDefaultBorderRadius {
  border-radius: 0 0 10px 10px;
}

.bottomRightBottomCornerStraight {
  border-radius: 0 0 0 10px;
}

.bottomTop{
  display: flex;
  justify-content: space-between;
  font-size: 0.85rem;
}

.bottomTopLeft{
  background-color: var(--peach600);
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  padding: 10px 0px 0px 10px;
  font-weight: 500;
  z-index: 2;
  max-width: 70%;
}

.bottomTopMiddle{
  background-color: var(--peach600);
  width: 45px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 30px;
  clip-path: polygon(0 0, 45% 0, 100% 100%, 0% 100%);
  z-index: 2;
  transform: translateX(12px);
}

.bottomTopRight{
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  height: 30px;
  background-color: var(--blue996);
  margin-left: -18px;
  z-index: 1;
  transform: translateY(-3px);
  justify-content: center;
  text-align: center;
  display: flex;
}

.pr24 {
  padding-right: 24px;
}

.poppinsFamily {
  font-family: 'Poppins';
}

.bottomBottom{
  padding: 10px;
}

.bottomBottomSlideOutPadding {
  padding: 12px !important;
}

.topCircle{
  background-color: var(--peach000);
  margin-left: 50px;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  z-index: 3;
  position: absolute;
  /* overflow: hidden; */
  display: flex;
  justify-content: center;
}

.innerCircle{
  z-index: 1;
  border-radius: 50%;
  margin-top: 5px;
}

.imageNoBorderRadius {
  height: 100px;
  z-index: 1;
  position: absolute;
  background-color: transparent;
  width: 100px;
  border: 4px solid transparent;
  object-fit: cover;
}

.image{
  height: 100px;
  z-index: 1;
  position: absolute;
  background-color: transparent;
  border-radius: 50%;
  width: 100px;
  border: 4px solid transparent;
  object-fit: cover;
}

.step1{
  display: flex;
  left: 10px;
  position: relative;
  margin-top: 7px;
  font-family: var(--typographyFontFamily);
  font-size: 0.8rem;
}

.stepAfter{
  display: flex;
  left: 10px;
  position: relative;
  margin-top: 13px;
  font-family: var(--typographyFontFamily);
  font-size: 0.8rem;
}

.stepCircle{
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 1px solid white;
  background-color: var(--blue996);
  position: absolute;
  margin-left: -32px;
}

.imgCircle {
  height: 100%;
  width: 100%;
  /* bottom: 1px; */
  color: white;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;
}

.imgCircle > img {
  min-width: 15px;
  max-width: 15px;
  padding-right: 1px;
}

.footer {
  background-color: var(--blue996);
  color: white;
  text-align: left;
  padding: 4px 4px 4px 10px;
  border-radius: 0 0 10px 10px;
  border-bottom: 1px solid var(--blue996);
  margin-bottom: -1px;
  font-size: 0.94rem;
  width: 100%;
  font-weight: 500;
}

.footer1 {
  color: var(--peach700);
}

.uncheckedBox {
  margin-top: -3px !important;
  display: inline-block;
  width: 17px !important;
  height: 15px !important;
  position: relative;
  margin-right: 2px;
  top: 1px;
  border-radius: 3px;
  background: white;
  border: 1px solid black !important;
}

.russellIntroImg{
  margin: 20px 0px;
}

.russellIntroImg img{
  width: 250px;
  border-radius: 10px;
}

.swagText{
  background: var(--peach600);
  border: 1px solid var(--peach700);
  padding: 15px 10px;
  border-radius: 10px;
  margin-top: -26px;
  z-index: 3;
  position: relative;
  width: 250px;
  /* left: 5%; */
  font-size: 13px;
}

.addMargin{
  margin-bottom: 250px;
}

.videoContainer{
  position: relative;
  transition: all 0.5s ease-in-out;
  border-radius: 50%;
  left: 50px;
  top: 0px;
  z-index: 8;
  cursor: pointer;
  margin-top: 30px;
}

.videoBox{
  position: absolute;
  transition: all 0.5s ease-in-out;
  width: 100px;
  height: auto;
  object-fit: cover;
  border-radius: 50%;

}

.expandedVideo{
  width: 234px;
  height: auto;
  border-radius: 10px;
  left: -45px;
}

.videoControls{
  position: absolute;
  display: flex;
  top: 70px;
}

.videoButton{
  color: white;
  background: black;
  border-radius: 50%;
  border: 1px solid white;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

@media (max-width: 900px) {
  .expandedVideo{
    width: 50vw;
  }

  .addMargin{
    margin-bottom: 200px;
  }
}

@media (min-width: 900px){
  .toTheRight{
    left: 238px !important;
    top: 60px !important;
  }
}