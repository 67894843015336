.ropeDrawer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  overflow: hidden;
  z-index: 290000000;
  transition: all .300s ease;
  height: 100vh;
  height: 100%;
  max-height: 100vh;
  pointer-events: none;
  background-color: transparent;
}

.ropeDrawer.isDrawerOpen {
  pointer-events: all;
  background-color: var(--shadow500);
}

.errandsBox {
  position: relative;
  display: flex;
  justify-content: flex-end;
  user-select: none;
  touch-action: none;
  -webkit-touch-callout: none;
  -Webkit-user-select: none;
}

.animated {
  transition: all 0.300s ease !important;
}

.childWrapper {
  min-width: 200px;
    width: 60vw;
    max-width: 300px;
  height: 100dvh;
  max-height: 100vh;
}

.pointerEventsAll {
  pointer-events: all !important;
}

.pointerEventsNone {
  pointer-events: none !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}
/* .childWrapper * {
  visibility: hidden !important;
}

.isDrawerOpen .childWrapper * {
  visibility: visible !important;
} */

.staticRopeWrapper {
  height: 100vh;
  height: 100%;
  max-height: 100vh;
  position: absolute;
  width: 25px;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 4;
  overflow: hidden;
}

.staticRopeWrapper img {
  width: 16px;
  height: auto;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  pointer-events: none;
}

.staticRopeWrapper.isPullingRope img {
  display: none;
}

.pullRopeWrapper {
  display: flex;
  position: absolute;
  background: transparent;
  width: 40px;
  height: 60vh;
  z-index: 10;
  right: 0;
  justify-content: flex-end;
  align-items: center;
  /* overFlow: hidden; */
}

.pullRopeWrapper.isPullingRope {
  height: 100vh;
  height: 100dvh;
  max-height: 100vh;
}

.pullRopeWrapper img {
  display: flex;
  width: 28px;
  height: auto;
  position: fixed;
  top: 50%;
  right: 0;
  transform: translate(16px, -50%);
  opacity: 0;
}

.nonSelectable {
  user-select: none !important;
  touch-action: none !important;
  -webkit-touch-callout: none !important;
  -Webkit-user-select: none !important;
}

.pullRopeWrapper.isPullingRope img {
  display: flex;
  opacity: 1;
  transform: translate(0, -50%);
}