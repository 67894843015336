.footer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 8px;
  border-top: 1px solid var(--gray820);
}

.footer form {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.footer p,
.footer input {
  background-color: var(--peach800);
  border: none;
  height: 40px;
  width: 100%;
  padding: 8px 40px 8px 8px;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--typographyFontFamily);
  color: var(--blue999);
}

.footer input::placeholder {
  color: var(--blue999);
}

.footer svg {
  position: absolute;
  top: 8px;
  right: 8px;
  pointer-events: none;
  color: var(--blue999);
}

.footer fieldset {
  border: none;
  border-radius: 4px;
}

.footer button {
  height: 40px;
  border-radius: 4px;
  border: 1px solid var(--peach800);
  color: var(--orange700);
  font-size: 14px;
  font-weight: 400;
  font-family: var(--typographyFontFamily);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


.footer button:hover,
.footer button:focus {
  font-weight: 600;
}