.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 290px;
  pointer-events: all;
  z-index: 1;
}

.alignByCurrentUser {
  margin-left: auto;
}

.message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: 1px solid var(--orange700);
  background-color: var(--peach600);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  width: 100%;
  padding: 5px 12px;
}

.message > div {
  font-size: 12px;
  color: var(--gray920);
  font-weight: bold;
  font-family: var(--typographyFontFamily);
}

.message > p {
  font-size: 14px;
  color: var(--gray600);
  font-weight: normal;
  font-family: var(--typographyFontFamily);
  line-height: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  width: 266px;
}

.sentByCurrentUser .message {
  background-color: var(--gray000);
}

.badge {
  background-color: var(--orange700);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  width: 100%;
  padding: 5px 12px;
  display: flex;
}

.badge img {
  width: 35px;
  height: 35px;
  margin-right: 8px;
  display: inline-block;
}

.name {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  font-family: var(--typographyFontFamily);
  color: var(--gray920);
  display: inline-block;
}

.name > * {
  display: block;
  font-size: 12px;
  color: var(--gray000);
  font-weight: bold;
  font-family: var(--typographyFontFamily);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.time {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: max-content;
  display: inline-block;
}

.time div:first-child {
  font-size: 12px;
  color: var(--gray000);
  font-weight: bold;
  font-family: var(--typographyFontFamily);
  width: max-content;
}
.time div:last-child {
  font-size: 10px;
  color: var(--gray920);
  font-weight: bold;
  font-family: var(--typographyFontFamily);
  width: max-content;
}