:root {
  --transition-duration: 500ms;
  --height-duration: 550ms;
  --margin-duration: 450ms;
  --width-duration: 500ms;
}

.avatarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;

  /* Border properties */
  border-radius: 50%;
  overflow: hidden;
  transform-origin: 0 100% 0;
  border: 1px solid var(--orange700);

  background-color: white;

  transition: transform var(--transition-duration) cubic-bezier(0.33, 1, 0.68, 1),
    width var(--width-duration) cubic-bezier(0.33, 1, 0.68, 1),
    height var(--height-duration) cubic-bezier(0.25, 1, 0.5, 1),
    margin-right var(--margin-duration) cubic-bezier(0.33, 1, 0.68, 1), border-color 0.3s ease-in-out;
}

.growing {
  transform: scale(1);
  margin-right: -10px;
  height: 150px;
  width: 150px;
}

.shrinking {
  transform: scale(0);

  /* Border properties gap 4px border 4px */
  margin-right: -8px;
  height: 0px;
  width: 0px;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
