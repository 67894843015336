.tableWrapper {
  overflow: auto;
  height: 100%;
  box-shadow: 0 0 10px var(--peach900);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.chatlogsTable {
  border-collapse: collapse;
  width: 100%;
  overflow: hidden;
}

.chatlogsTable th,
.chatlogsTable td {
  border: 1px solid var(--primaryLight);
  padding: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 500px;
  overflow: hidden;
}

.chatlogsTable th {
  background-color: var(--peach900);
}

.chatlogsTable th:nth-child(1) {
  width: 111px;
}

.chatlogsTable th:nth-child(2) {
  width: 85px;
}

.chatlogsTable th:nth-child(3) {
  width: 500px;
}

.chatlogsTable th:nth-child(4) {
  width: 189px;
}

.chatlogsTable td.expanded {
  white-space: normal;
  overflow: auto;
}

.filterTitle{
  color: var(--blue010);
  text-transform: none;
  font-size: 1rem;
  font-weight: 600;
}

.filterDropdown {
  position: absolute;
  background-color: var(--gray000);
  border: 1px solid var(--gray060);
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 5px var(--shadow110);
  display: none;
}

.chatlogsTable th {
  position: relative;
  color: var(--blue010);
}

.chatlogsTable th:hover .filterDropdown {
  display: block;
}

.filterDropdown label {
  display: block;
  margin-bottom: 5px;
}

.filterDropdown input[type='checkbox'] {
  margin-right: 5px;
}

.chatlogSearch {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  gap: 10px;
}

.chatlogsTable tbody tr:nth-child(even) {
  background-color: var(--peach100);
}

.chatlogsTable tbody tr:nth-child(odd) {
  background-color: var(--gray000);
}

.chatDownButton {
  position: absolute;
  right: 30px;
  bottom: 75px;
}

.chatDownIcon {
  width: 50px;
  height: 50px;
  color: var(--peach900);
}

.noLogs {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
  background: var(--primaryLight);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 6px;
}
