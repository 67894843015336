.pageContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 100%;
  max-height: 100vh;
  max-height: 100dvh;
  background: var(--gray000);
  overflow: hidden;
  /* filter: blur(16px); */
  transition: all 0s linear;
}

.disablePointerEvents * {
  pointer-events: none !important;  
}

@media (max-width: 900px) {
  .pageContainer {
    padding: 0;
  }
}

.unblur {
  filter: blur(0);
  transition: all 0s linear;
}