.link {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: max-content;
  font-size: 14px;
  font-family: var(--typographyFontFamily);
  height: 29px;
  align-items: center;
  color: var(--orange400);
  text-decoration: none;
  cursor: pointer;
}