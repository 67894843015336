.style {
  border: 1px solid var(--orange700);
  color: var(--gray920);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-end;
  border-radius: 8px;
  padding: 6px 7px 8px 9px;
  column-gap: 4px;
  background-color: var(--peach600);
  width: fit-content;
  max-width: 60%;
}

.internal {
  border: 1px solid var(--peach020);
  background-color: var(--gray400);
}

.sentByCurrentUser {
  background-color: var(--gray000);
}

.peNone {
  pointer-events: none;
}

.peAll {
  pointer-events: all;
}

@media (max-width: 900px) {
  .style {
    max-width: 90%;
  }
}