.rootContainer {
  margin-top: 15px;
  overflow-x: clip; /* hide the animated truck after moving out of container */
}

.bubbleContainer {
  position: relative;
  height: fit-content;
  width: 100%;
  background-color: var(--peach600);
  border: solid 1px var(--orange700);
  border-radius: 10px;
  display: flex;
  overflow-y: visible;
}

.bubbleContainer:hover {
  cursor: pointer;
}

.left {
  position: relative;
  max-width: 160px;
  padding: 10px;
  font-size: 0.8rem;
  font-family: var(--typographyFontFamily);
}

.right {
  position: relative;
  width: fit-content;
  height: fit-content;
}

/* 
.topCircle styles the top bump of the message bubble which includes the 
seamless continuation of the orange border over the bump. 

Parent element is a circle with a border the same as the bubble's border. 

The ::before pseudo-element is a rectangle with the same background color as 
the bubble's background designed to mask the borders of the parent element. 

The ::after pseudo-element is a circle with the same background color as the 
bubble's background color

The same idea applies to the .bottomCircle style
*/
.topCircle {
  display: flex;
  justify-content: center;
  position: absolute;
  background-color: var(--peach600);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  top: -20px;
  left: 50%;
  translate: -50%;
  border: solid 1px var(--orange700);
}

.topCircle::before {
  content: '';
  position: absolute;
  background-color: var(--peach600);
  bottom: 0px;
  width: calc(100% + 4px);
  height: calc(50% + 20px);
  left: 50%;
  translate: -50%;
}

.topCircle::after {
  content: '';
  position: absolute;
  background-color: var(--orange700);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  left: 50%;
  translate: -50%;
  top: 5px;
}

.topCircleDot {
  position: relative;
  width: 8px;
  height: 8px;
  background-color: var(--gray000);
  border-radius: 50%;
  z-index: 1;
  top: 12px;
}

.miniSlotMachineContainer {
  position: relative;
  margin: 5px;
  background-color: var(--peach400);
  border: solid 3px var(--orange700);
  border-radius: 10px;
  width: 155px;
  height: 85px;
  padding: 5px;
}

.slotsContainer {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 5px;
  display: flex;
  gap: 5px;
  padding: 5px 5px 12px 5px;
}

.slot {
  flex: 1;
  max-width: 33%;
  border: solid 1px var(--orange700);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slot img {
  width: 100%;
}

.playButton {
  display: flex;
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: var(--orange700);
  border-radius: 50%;
  bottom: 0px;
  left: 50%;
  translate: -50% calc(50% + 2px);
  justify-content: center;
  align-items: center;
}

.playButton > svg {
  fill: var(--gray000);
}

.bottomCircle {
  position: absolute;
  background-color: var(--peach600);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  bottom: -15px;
  left: 50%;
  translate: -50%;
  border: solid 1px var(--orange700);
}

.bottomCircle::before {
  content: '';
  position: absolute;
  background-color: var(--peach600);
  width: calc(100% + 4px);
  height: calc(50% + 5px);
  left: 50%;
  translate: -50%;
}

/* CSS rules for the countdown UI */
.timerContainer {
  width: 150px;
  height: 90px;
}

.timerTopCircle {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  background-color: var(--peach600);
  width: 115px;
  height: 115px;
  border-radius: 50%;
  top: -40px;
  left: 50%;
  translate: -50%;
  border: solid 1px var(--orange700);
  border-bottom: 0;
  padding-top: 10px;
}

.timerTopCircle::before {
  content: '';
  position: absolute;
  background-color: var(--peach600);
  top: 39.5px;
  width: calc(100% + 4px);
  height: calc(50% + 20px);
  left: 50%;
  translate: -50%;
}

.timer {
  position: relative;
  text-align: center;
  font-family: var(--typographyFontFamily);
  top: 18px;
  line-height: 1.15;
}

.timer p {
  text-transform: capitalize;
}

.timerTopCircle > img {
  position: absolute;
  left: 50%;
  translate: -50%;
  z-index: 1;
  height: 85px;
  width: 85px;
}

.timerContainer > img {
  position: absolute;
  z-index: 1;
  bottom: 5px;
  left: 50%;
  translate: -50%;
}

.timer > p {
  font-size: 9pt;
}
