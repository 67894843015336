.errand {
  width: 100%;
}

.errand:hover,
.errand.isDragging {
  background-color: var(--gray050);
}

.errand.isSelected {
  background-color: var(--orange000);
}

.errand.isSelected>div>button>p,
.errand.isSelected>div>button>h2{
  color: var(--blue010);
  font-weight: 600;
}

.errand.isMatch {
  background-color: var(--peach700);
}

.errand.isMatch>div>button:last-child,
.errand.isSelected>div>button:last-child {
  pointer-events: none;
}

.disabled * {
  cursor: not-allowed !important;
  /* pointer-events: none; */
}

.errand>div {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  justify-content: flex-start;
  min-height: 60px;
  max-width: 100%;
}

.errand:hover>div {
  border-left: 1px solid var(--orange900);
}
.errand.isSelected:hover>div {
  border-left: unset;
}

.errand>div>button:first-child {
  position: relative;
  min-width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.errand.isChecked .waveMiddle::after,
.errand.isChecked>div>button:first-child::after {
  content: '\2713';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  background-color: var(--orange000);
  color: var(--blue999);
  transform: translate(-50%, -50%);
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.errand.isChecked .waveMiddle::after {
  width: 20px;
  height: 20px;
  background-color: var(--shadow000);
  font-size: 20px;
}
.errand.isSelected.isChecked>div>button:first-child::after,
.errand.isSelected>div>button:first-child>*:first-child {
  opacity: 0;
}

.showFooter {
  grid-template-rows: 1fr !important;
  display: grid !important;
}

.hideFooter {
  grid-template-rows: 0fr !important;
  display: none !important;
}

.errand>footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0fr;
  align-items: center;
  justify-content: space-around;
  border-left: 1px solid var(--orange900);
  overflow: hidden;
  transition: all .5s linear;
}

.errand.isDragging:not(.isSelected)>footer {
  border-left: unset;
}
.errand.isDragging.isSelected>footer {
  border-left: 1px solid var(--orange900);
}

.errand.isDefault>footer {
  grid-template-columns: 1fr;
}

.errand.isDefault>footer>button {
  display: none;
}

.errand.isSelected>footer,
.errand.isChecked>footer {
  grid-template-rows: 1fr;
  transition: all .5s linear;
}

.errand:hover>footer,
.errand.isDragging>footer {
  grid-template-rows: 1fr;
  transition: all .5s linear;
}

.errand>footer>button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 4px;
  height: fit-content;
  min-height: 0;
  position: relative;
  color: var(--blue999);
}

.errand.isSelected>footer>button{
  color: var(--blue010);
}

.errand>footer>button:first-child>svg:first-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%;
  z-index: 1;
}

.errand>footer>button>svg {
  color: inherit;
  margin: 8px 0;
}

.errand>div>button:last-child {
  display: block;
  padding: 8px 4px 8px 0;
  max-width: 100%;
  overflow: hidden;
}

.errand h2,
.errand p {
  text-align: left;
  font-family: var(--typographyFontFamily);
  color: var(--blue999);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
}

.errand h2 {
  font-size: 14px;
  font-weight: 500;
}

.errand p {
  font-size: 12px;
  filter: blur(.3em);
  word-break: break-all;
}

.errand.messageHistoryAllowed p {
  filter: unset;
}

.errand>div>button:first-child>*:first-child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.waveBg {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) translateX(-100%);
  width: calc(50% + 15px);
  height: 30px;
  background-color: var(--gray000);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border: 1px solid var(--peach700);
  border-left: none;
  opacity: 0;
}

@media (max-width: 900px) {
  .waveBg {
    background-color: var(--gray520);
  }
}

.errand.isMatch .waveBg,
.errand.isSelected .waveBg {
  opacity: 1;
  transform: translateY(-50%) translateX(0%);
  transition: transform .25s linear;
}

.waveBg>span {
  width: 0;
  height: 0;
}

.waveBg>span::before,
.waveBg>span::after,
.waveBg::before,
.waveBg::after {
  position: absolute;
  left: 0;
  display: block;
  content: '';
  width: 15px;
  height: 15px;
}

.waveBg::before,
.waveBg>span::after {
  background-color: var(--gray000);
}

@media (max-width: 900px) {
  .waveBg::before,
  .waveBg>span::after {
    background-color: var(--gray520);
  }
}

.waveBg>span::before {
  top: -15px;
  height: 14px;
}

.waveBg::before {
  top: -15px;
}

.waveBg::after {
  bottom: -16px;
}

.waveBg>span::after {
  bottom: -15px;
}

.errand.isSelected .waveBg>span::before {
  border-left: 1px solid var(--orange900);
  border-bottom: 1px solid var(--orange900);
  border-bottom-left-radius: 15px;
  background-color: var(--orange000);
}

.errand.isSelected .waveBg::after {
  border-left: 1px solid var(--orange900);
  border-top: 1px solid var(--orange900);
  border-top-left-radius: 15px;
  background-color: var(--orange000);
}

.errand.isMatch .waveBg>span::before {
  border-left: 1px solid var(--orange900);
  border-bottom: 1px solid var(--orange900);
  border-bottom-left-radius: 15px;
  background-color: var(--peach700);
}

.errand.isMatch .waveBg::after {
  border-left: 1px solid var(--orange900);
  border-top: 1px solid var(--orange900);
  border-top-left-radius: 15px;
  background-color: var(--peach700);
}

.waveMiddle {
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  transform: translateY(-50%) translateX(-100%);
  opacity: 0;
}

.errand.isMatch .waveMiddle,
.errand.isSelected .waveMiddle {
  opacity: 1;
  transform: translateY(-50%) translateX(-50%);
  transition: opacity .5s linear .25s, transform .5s linear .25s;
}

.errand.isSelected .waveMiddle {
  background-color: var(--peach700);
}

.errand.isMatch .waveMiddle {
  background-color: var(--orange000);
}

.errand p.errandPreview {
  word-break: normal;
}

.avatar{
  pointer-events: none;
}