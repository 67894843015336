.box {
  position: absolute;
  top: calc(100% - 70px);
  left: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--gray000);
  border-radius: 8px;
  border: 1px solid var(--peach200);
  color: var(orange510);
  position: relative;
  width: calc(100vw - 390px);
  height: 50px;
}

.operatorView {
  width: calc(100vw - 80px);
}

.messages {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100vw - 320px);
  height: calc(100% - 70px);
  z-index: -2;
}

.box hr {
  display: block;
  width: 1px;
  height: 22px;
  background-color: var(--orange700);
  border: none;
  outline: none;
}

.imgWrapper {
  width: 45px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgWrapper img {
  height: 25px;
  position: relative;
  margin: 0;
  background-color: var(orange510);
  border-radius: 5px;
  padding: 4px;
}

.box p {
  color: var(--gray600);
  font-size: 16px;
  font-weight: 400;
  font-family: var(--typographyFontFamily);
  padding: 0;
  margin: 0;
  padding-left: 8px;
}

.box svg {
  transform: translate(-50%, -50%) rotate(90deg);
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: -2px;
}

.mid {
  animation: 2s ease blink infinite;
}

.right {
  animation: 2s ease blink infinite;
  animation-delay: 0.4s;
}

@keyframes blink {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (max-width: 900px) {
  .box {
    top: calc(100% - 64px);
    left: 20px;
    width: calc(100vw - 40px);
    height: 54px;
  }
  .messages {
    left: 5px;
    width: calc(100vw - 10px);
    height: calc(100% - 64px);
  }
}