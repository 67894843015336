.messageContentWrapper {
  display: block;
  position: relative;
  margin: 5px 0;
  -webkit-text-size-adjust: none;
}

.messageContentWrapper::before {
  content: '';
  display: none;
  height: calc(100% - 1px);
  width: 0;
  position: absolute;
  top: 0;
  right: -9px;
  border-left: 1px dashed var(--orange700);
  border-right: 1px dashed var(--orange700);
}

.messageContentWrapper.isMorphedFooterCloseButtonOnLeft::before {
  right: unset;
  left: -9px;
}

.messageContentWrapper.renderBorder::before {
  display: block;
}

.messageContentWrapper.renderNode::before {
  height: calc(100% - 19px);
  top: 19px;
}

.messageContentWrapper.showDate::before {
  height: 100%;
  top: 0;
}

.messageContentWrapper.renderNode.showDate::before {
  height: calc(100% - 49px);
  top: 49px;
}

.messageContentWrapper::after {
  content: '';
  display: none;
  height: 0px;
  width: 0;
  position: absolute;
  bottom: 0px;
  right: -9px;
  border-left: 1px dashed var(--orange700);
  border-right: 1px dashed var(--orange700);
}

.messageContentWrapper.isMorphedFooterCloseButtonOnLeft::after {
  right: unset;
  left: -9px;
}

.messageContentWrapper.renderBorder.isLastMessage::after {
  display: flex;
  height: 80px;
  bottom: -80px;
}

.alignByCurrentUser {
  align-items: flex-end;
}

.node {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--peach000);
  display: none;
  top: 9px;
  right: -16px;
  transform: translateY(-50%);
  justify-content: center;
  align-items: center;
}

.renderNode .node {
  display: flex;
}

.showDate .node {
  top: 39px;
}

.isMorphedFooterCloseButtonOnLeft .node {
  right: unset;
  left: -16px;
}

.node::before {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: linear-gradient(180deg, var(--peach600) 0%, var(--orange700) 100%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.node::after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: linear-gradient(180deg, var(--orange700) 0%, var(--peach600) 100%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button {
  min-width: 40px;
  width: 40px;
  height: 40px;
  overflow: visible;
  background-color: transparent;
  border-radius: 50%;
  color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
  pointer-events: none;
}

/*
The hidden to shown transition is done using color and background color.
For some reason if opacity is used it makes the on click events for the other
elements get disabled, making the user have to click twice to make them fire.
*/
.messageContentWrapper:hover .button,
.messageContentWrapper:focus .button,
.messageContentWrapper:focus-within .button {
  color: var(--peach500);
  background-color: var(--gray020);
  pointer-events: all;
  transition: all 0.25s linear;
  animation: delay-pointer-events 0.25s linear forwards;
}

.messageContentWrapper:hover .button:hover,
.messageContentWrapper:focus .button:focus,
.messageContentWrapper:focus-within .button:focus-within {
  color: var(--orange600);
}

@keyframes delay-pointer-events {
  0%,
  99% {
    pointer-events: none;
  }
  100% {
    pointer-events: all;
  }
}

.alignByCurrentUser .button {
  margin-right: 0;
}

.button:hover,
.button:focus {
  color: var(--orange600);
}

.button svg {
  font-size: 25px;
  pointer-events: none;
  height: 25px;
  width: 25px;
}

.messageWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 4px;
}

.alignByCurrentUser .messageWrapper {
  justify-content: flex-end;
}

.blur {
  filter: blur(0.25em);
  transform: translate3d(0px, 0px, 0px);
}

.blur * {
  pointer-events: none !important;
  cursor: default !important;
  user-select: none !important;
}

.sentiment {
  background-color: var(--gray020);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: default;
}
.sentiment * {
  font-size: 24px !important;
}

.hidden {
  display: none;
}

.disable {
  cursor: not-allowed !important;
}
