.wrapper {
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  pointer-events: all;
  padding: 4px 0px;
  color: var(--gray600);
  font: inherit;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 1rem;
  display: flex;
  cursor: text;
}
