.PageSection {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: var(--gray000);
}

.ConversationsContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  background: var(--gray000);
  position: relative
}

@media (max-aspect-ratio: 16/10) {
  .ConversationsContainer {
    flex-direction: column;
    gap: 0;
  }
}
