.titleContainer p {
  background-color: var(--orange700);
  padding: 8px 7px 4px 9px;
  color: white !important;
  font-weight: 500 !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  line-height: 20px;
}

.optionsContainer {
  padding: 6px 0px 9px 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.optionContainer {
  display: flex;
  align-items: center;
}

.optionNumber {
  border-radius: 50%;
  background-color: var(--orange700);
  position: absolute;
  width: 18px;
  height: 18px;
  left: -10px;
  color: white;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1px;
  cursor: pointer;
}

.selectedNumber {
  background-color: green;
}
