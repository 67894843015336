.aside {
  background-color: var(--gray000);
  height: 100%;
  outline: 1px solid var(--peach900);
  outline-offset: -1px;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: space-between;
  border-radius: 0;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
}

.aside.isDesktop {
  border-radius: 8px;
  min-width: 300px;
  width: 300px;
  max-width: 300px;
}

.ul {
  width: 100%;
  height: 100%;
  padding-top: 0;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: auto;
  flex: 1;
  list-style-type: none;
  position: relative;
  padding-bottom: 43px;
  scrollbar-width: thin;
}

.ul li {
  display: flex;
}
.errandNotice {
  display: block;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: thin;
  color: var(--gray600);
  text-align: justify;
}