.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.opacityFull {
  opacity: 1;
}

.opacityZero {
  opacity: 0;
}

.letter {
  transition: all 0.3s ease;
  display: inline-block;
}
