.charCountWrapper{
  position: fixed;
  width: 55px;
  height: 10px;
  display: flex;
  justify-content: center;
}

.charMobile{
  left: 24px;
  bottom: 22px;
}

.charDesktop{
  left: 47px;
  bottom: 29px;
}

.charCount{
  font-size: 12px;
}

.noChar{
  color: var(--gray200);
}

.reachedLimit{
  color: var(--red400);
}