.BWMC_title-container-master-slotMachine::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--peach700);
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 0px 7px 0 7px;
}

@keyframes enlarge {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }
}

.BWMC_subContent-gif {
    transition: all 0.4s ease;
}

.BWMC_subContent-gif-enlarge {
    animation-name: enlarge;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
}


.BWMC_subContent-gif-enlarge-slotMachine {
    animation-delay: 2s;
    animation-duration: 1.6s;
}

.BWMC_subContent-gif-force-enlarge {
    transform: scale(1.2) !important;
}