.attchMenuBtnWrapper {
  background-color: var(--orange000);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  max-width: fit-content;
  height: 100%;
  margin-top: -6px;
  padding: 8px;
  position: relative;
  min-width: 40px;
  color: var(--gray000);
  cursor: pointer;
  border: none;
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
}

.attchMenuBtnWrapper:hover svg,
.attchMenuBtnWrapper:focus svg {
  opacity: .9;
}

button.attchMenuBlueBtnWrapper {
  background-color: var(--blue930);
}

button.attchMenuRedBtnWrapper {
  background-color: var(--blue300);
}

button.attchMenuDarkBlueBtnWrapper {
  background-color: var(--blue995);
}

.attchMenuBtnWrapper::before {
  position: absolute;
  left: -9px;
  top: 0px;
  width: 10px;
  height: 10px;
  content: '';
  display: block;
  background: radial-gradient(circle at 0% 100%, transparent 8px, var(--orange000) 9px, var(--orange000) 10px);
}

button.attchMenuBlueBtnWrapper::before {
  background: radial-gradient(circle at 0% 100%, transparent 8px, var(--blue930) 9px, var(--blue930) 10px);
}
button.attchMenuRedBtnWrapper::before {
  background: radial-gradient(circle at 0% 100%, transparent 8px, var(--blue300) 9px, var(--blue300) 10px);
}
button.attchMenuDarkBlueBtnWrapper::before {
  background: radial-gradient(circle at 0% 100%, transparent 8px, var(--blue995) 9px, var(--blue995) 10px);
}
.attchMenuBtnWrapper.left::before {
  left: unset;
  right: -9px;
  background: radial-gradient(circle at 100% 100%, transparent 8px, var(--orange000) 9px, var(--orange000) 10px);
}

.attchMenuBtnWrapper svg {
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
}

@media (min-width: 900px) {
  .attchMenuBtnWrapper {
    height: calc(100% - 6px);
    margin-top: -15px;
  }
}

@media (max-width: 900px){
  .attchMenuBtnWrapper{
    margin-bottom: 15px;
  }

  .attchMenuBtnWrapper svg{
    margin-top: 25px;
  }
  .attchMenuBtnWrapper::before{
    height: 20px;
  }
}
