.Style {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.Negative {
  animation: pulse 1s infinite;
}

/* fixed invalid css */
@-webkit-keyframes pulse {
  0% {
    webkit-box-shadow: 0 0 0 0 var(--shadow403);
  }

  70% {
    webkit-box-shadow: 0 0 0 8px var(--shadow000);
  }

  100% {
    webkit-box-shadow: 0 0 0 0 var(--shadow000);
  }
}

/* fixed invalid css */
@keyframes pulse {
  0% {
    moz-box-shadow: 0 0 0 0 var(--shadow403);
    box-shadow: 0 0 0 0 var(--shadow402);
  }

  70% {
    moz-box-shadow: 0 0 0 8px var(--shadow000);
    box-shadow: 0 0 0 8px var(--shadow000);
  }

  100% {
    moz-box-shadow: 0 0 0 0 var(--shadow000);
    box-shadow: 0 0 0 0 var(--shadow000);
  }
}