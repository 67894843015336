.wrapper {
  height: 27.37px;
  padding: 1px 5px 0px 5px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.6));
  border-radius: 50px;
  color: white;
  font-family: var(--typographyFontFamily);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.icon {
  transform: translateY(-1px);
}

.numberText {
  font-weight: 700;
}
.pointsText {
  font-weight: normal;
  font-size: xx-small;
  text-transform: capitalize;
}
