.otpWrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
}

.otpSingleBox{
  border: 2px solid var(--peach700);
  border-radius: 10px;
  margin: 0 3px;
  max-width: 33px;
  height: 30px;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 1px;
}

@keyframes shake {
  25% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  50% {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
  }
  75% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
}

.otpWrapper .focused,
.otpWrapper .otpSingleBox:focus,
.otpWrapper .otpSingleBox:active {
  border: 3px solid var(--orange050);
  outline: none;
}

.rightOfOtp{
  width: 100%;
}

.otpWrapper .errorOn{
  animation: shake 300ms;
  -webkit-animation: shake 300ms;
  border-color: red !important;
}

@media (max-width: 400px){
  .otpSingleBox{
    max-width: 25px;
  }
}

.overInput {
  border: 1px solid transparent;
  position: absolute;
  height: 30px;
  background: transparent;
  letter-spacing: 30px;
  font-size: 1rem;
  padding-left: 14px;
  width: 176px;
  outline: none;
}

.hideCaret {
  caret-color: transparent;
}

@media (max-width: 400px){
  .overInput{
    letter-spacing: 22px;
    padding-left: 10px;
    width: 155px;
  }
}